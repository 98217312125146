export default {
  marquee: [
    `PAY ATTENTION TO ME!!! OH, HI THERE <span>😎😎😎</span> NEW SHOWS ADDED EVERY DAY!!!`,
    `DON'T FORGET IT'S YOUR CIVIC DUTY TO VOTE FOR YOUR FAVE QUEEN IN THE DIGITAL DRAG FEST AWARDS`,
    `CYNTHIA LEE FONTAINE IS DOING THE CABRONA <span>🦠</span> VIRUS SHOW… IS THERE GONNA BE A CABROMANCE TOO?`,
    `GINGER MINJ IS DOING SHOWTUNES & SH!T...WE CAN’T WAIT TO SEE THE SH!T!`,
    `VALENTINA IS GONNA GO LIVE!?! WE WILL HAVE THE RED M&M’S READY!!`,
    `BETTY BITSCHLAP, WHAT VINTAGE IS YOUR CABERNET? <span>🍷🍷🍷</span>`,
    `BRITA PLEASE SEND FILTERS!!`,
    `DEVEN GREEN, DO YOU HAVE A GLUTEN FREE OPTION FOR YOUR SHOW??`,
  ],
  shows: [
    {
      title: "Opening Ceremony with Michelle Visage",
      artist: "Michelle Visage",
      image: "/images/michelle.png",
      soldOut: false,
    },
    {
      title: "Call Me By Monét: (Abridged)...Kinda",
      artist: "Monét X Change",
      image: "/images/monet.png",
      soldOut: false,
    },
    {
      artist: "Nina West",
      image: "/images/nina-west.png",
    },
    {
      title: "COVIDivinations",
      artist: "John Cameron Mitchell",
      image: "/images/john-cameron-mitchell-2.png",
      soldOut: false,
    },
    {
      title: "The Corona Cabaret",
      artist: "Alaska",
      image: "/images/alaska.png",
      soldOut: false,
    },
    {
      title: "Brunch with The BAWDY!",
      artist: "A’KERIA CHANEL DAVENPORT",
      image: "/images/akeria.png",
      soldOut: false,
    },
    {
      title: "Roast of Nut-MEH",
      artist: "Silky Nutmeg Ganache",
      image: "/images/silky-ganache.png",
      soldOut: false,
    },
    {
      title: "American Woman: Quarantine Edition",
      artist: "Miz Cracker",
      image: "/images/cracker.png",
      soldOut: true,
    },
    {
      title: "Terminally Detained",
      artist: "BenDeLaCreme",
      image: "/images/ben.png",
      soldOut: true,
    },
    {
      title: "Cursed",
      artist: "Jinkx Monsoon",
      image: "/images/jinkx.png",
      soldOut: true,
    },
    {
      title: "Quarantina (I’d Like To Keep My N95 On)",
      artist: "Trinity the Tuck",
      image: "/images/trinity.png",
      soldOut: false,
    },
    {
      title: "One Woah!Man Show",
      artist: "Landon Cider",
      image: "/images/ladon-cider.png",
      soldOut: false,
    },
    {
      title: "Beauty Essentials with the Essence of Beauty",
      artist: "Jaida Essence Hall",
      image: "/images/jaida-essence-hall.png",
      soldOut: false,
    },
    {
      title: "The Jackie Cox Variety Show",
      artist: "Jackie Cox",
      image: "/images/jackie-cox.png",
      soldOut: false,
    },
    {
      title: "Watch Thin People Eat: Tasty Songs of Food and Drink!",
      artist: "Deven Green",
      image: "/images/deven-green-ned-douglas.png",
      soldOut: false,
    },
    {
      title: "Ask Jaidynn Live!",
      artist: "Jaidynn Diore Fierce",
      image: "/images/jaidynn.png",
      soldOut: false,
    },
    {
      title: "The Mama Tits Show!",
      artist: "Mama Tits",
      image: "/images/mama-tits.png",
      soldOut: false,
    },
    {
      title: "There’s No Place Like Home",
      artist: "Mrs. Kasha Davis",
      image: "/images/kasha.png",
      soldOut: false,
    },
    {
      title: "QuaranTINA Burner presents MIXTAPE MADNESS",
      artist: "Tina Burner",
      image: "/images/tina-burner.png",
      soldOut: false,
    },
    {
      title: "Throwback Attack",
      artist: "TENDERONI",
      image: "/images/tenderoni.png",
      soldOut: false,
    },
    {
      title: "Sheila's Spectacular Shit Show",
      artist: "Kelly Mantle",
      image: "/images/kelly-mantle.png",
      soldOut: false,
    },

    {
      artist: "MANILA LUZON",
      image: "/images/MANILA-LUZON.png",
    },
    {
      title: "Country Lockdown",
      artist: "Brandon Stansell",
      image: "/images/brandon.png",
      soldOut: false,
    },
    {
      title: "Just Jan",
      artist: "Jan Sport",
      image: "/images/jan-sport.png",
      soldOut: false,
    },
    {
      title: "Quarantine and Curl",
      artist: "Blair St. Clair",
      image: "/images/blair-st-clair.png",
      soldOut: false,
    },
    {
      title: "Bratz Doll Live",
      artist: "Ariel Versace",
      image: "/images/ariel.png",
      soldOut: false,
    },
    {
      title: "Jiggly’s Got That Yummy Yum",
      artist: "Jiggly Caliente",
      image: "/images/jiggly.png",
      soldOut: false,
    },
    {
      title: "A Little Bit of Quarantined Tammie",
      artist: "Tammie Brown",
      image: "/images/tammie.png",
      soldOut: false,
    },
    {
      title: "Cooking with Couleé",
      artist: "Shea Couleé",
      image: "/images/shea-coolee.png",
      soldOut: false,
    },
    {
      title: "Live from Boston (at home with my cats)",
      artist: "Jujubee",
      image: "/images/juju.png",
      soldOut: false,
    },
    {
      title: "Live! From The Safety of My Bedroom!",
      artist: "Jackie Beat",
      image: "/images/jackie-beat.png",
      soldOut: false,
    },
    {
      title: "Xperience the DOLL",
      artist: "Nicky Doll",
      image: "/images/nicky-doll.png",
      soldOut: false,
    },
    {
      title: "Queen4Queen",
      artist: "Dahlia Sin",
      image: "/images/dahlia-sin.png",
      soldOut: false,
    },
    {
      title: "Tucked & Fully Loaded",
      artist: "Widow Von'du",
      image: "/images/window-von-du.png",
      soldOut: false,
    },
    {
      title: "At Home With Divina De Covid",
      artist: "Divina De Campo",
      image: "/images/divina.png",
      soldOut: false,
    },
    {
      title: "At Home With Divina De Covid",
      artist: "Divina De Campo",
      soldOut: false,
    },
    {
      title: "Peppermint Unplugged",
      artist: "Peppermint",
      image: "/images/peppermint.png",
      soldOut: false,
    },
    {
      title: "Call Me On The Ouija Board",
      artist: "Sharon Needles",
      image: "/images/sharon.png",
      soldOut: true,
    },
    {
      title: "The Baga Chipz Show",
      artist: "Baga Chipz",
      image: "/images/baga-chipz.png",
      soldOut: false,
    },
    {
      title: "Total Slag On Cam",
      artist: "Vinegar Strokes",
      image: "/images/vinegar-strokes.png",
      soldOut: false,
    },
    {
      title: "Shug's Easter EGG-stravaganza",
      artist: "Shuga Cain",
      image: "/images/shuga-cain.png",
      soldOut: false,
    },
    {
      title: "Scarlet Envy: Love Songs",
      artist: "Scarlet Envy",
      image: "/images/scarlet.png",
      soldOut: false,
    },
    {
      title: "#QuarantineQueen",
      artist: "Rhea Litré",
      image: "/images/rhea-litre.png",
      soldOut: false,
    },
    {
      title: "Justin Vivian Bond is Your Auntie Glam",
      artist: "Justin Vivian Bond",
      image: "/images/justin-vivian-bond.png",
      soldOut: false,
    },
    {
      title: "Showtunes & Sh!t With Ginger Minj!",
      artist: "Ginger Minj",
      buttonMessage: "2nd Show Added",
      image: "/images/ginger.png",
      soldOut: false,
    },
    {
      title: "Marti’s Greatest Drag Mixes",
      artist: "Marti Gould Cummings",
      image: "/images/marti-cummings.png",
      soldOut: false,
    },
    {
      title: "The Cabronavirus Show",
      artist: "Cynthia Lee Fontaine",
      image: "/images/cynthia-lee-fontaine.png",
      soldOut: false,
    },
    {
      title: "Heklina-Live From The Apocalypse!",
      artist: "Heklina",
      image: "/images/heklina.png",
      soldOut: false,
    },
    {
      title: "VALENTINA Live!",
      artist: "VALENTINA",
      image: "/images/valentina.png",
      soldOut: false,
    },
    {
      title: "Heidi’s Closet",
      artist: "Heidi N Closet",
      image: "/images/heidi-n-closet.png",
      soldOut: false,
    },
    {
      artist: "FENA BARBITALL",
      image: "/images/fena-berbitall.png",
    },
    {
      artist: "BLU HYDRANGEA",
      image: "/images/blu-hydrangea.png",
    },
    {
      artist: "ROCK M. SAKURA",
      image: "/images/rock-m-sakura.png",
    },
    {
      artist: "Rock M Sakura",
      image: "/images/rock-m-sakura.png",
    },
    {
      artist: "VARLA JEAN MERMAN",
      image: "/images/Varla.png",
    },
    {
      artist: "KIMORA BLAC ",
      image: "/images/KimoraBlac.png",
    },
    {
      artist: "BETTY BITSCHLAP",
      image: "/images/BettyBitchslap.png",
    },
    {
      artist: "DUSTY RAY BOTTOMS",
      image: "/images/DustyRayBottoms.png",
    },
    {
      artist: "NADYA GINSBURG",
      image: "/images/Nadya.png",
    },
    {
      artist: "MERCEDES IMAN DIAMOND",
      image: "/images/Mercedes.png",
    },
    {
      artist: "RAJA GEMINI",
      image: "/images/Raja.png",
    },
    {
      artist: "CAZWELL",
      image: "/images/empty.png",
    },
    {
      artist: "NINA FOR KIDS",
      image: "/images/nina-kids.png",
    },
    {
      artist: "RAYVON OWEN",
      image: "/images/RayvonOwen.png",
    },
    {
      artist: "Ab Farrelly",
      image: "/images/ab-farrelly.png",
    },
    {
      artist: "amanda lepore",
      image: "/images/amanda-lepore.png",
    },
    {
      artist: "BRITA FILTER",
      image: "/images/brita-filter.png",
    },
    {
      artist: "crystal",
      image: "/images/crystal.png",
    },
    {
      artist: "delta work",
      image: "/images/delta-work.png",
    },
    {
      artist: "honey davenport",
      image: "/images/honey-davenport.png",
    },
    {
      artist: "kennedy davenport",
      image: "/images/kennedy-davenport.png",
    },
    {
      artist: "kitty tray",
      image: "/images/kitty-tray.png",
    },
    {
      artist: "LATRICE ROYALE",
      image: "/images/latrice-royal.png",
    },
    {
      artist: "MADDELYNN HATTER",
      image: "/images/maddelyn-hatter.png",
    },
    {
      artist: "ROSÉ",
      image: "/images/rose.png",
    },
    {
      artist: "sonique",
      image: "/images/sonique.png",
    },
    {
      artist: "sum ting wong",
      image: "/images/sum-ting-wong.png",
    },
    {
      artist: "tom goss",
      image: "/images/tom-goss.png",
    },
    {
      artist: "virginia west",
      image: "/images/virginia-west.png",
    },
    {
      artist: "boulet brothers",
      image: "/images/boulet-brothers.png",
    },
    {
      artist: "THE BOULET BROTHERS",
      image: "/images/boulet-brothers.png",
    },
    {
      artist: "aja",
      image: "/images/aja.png",
    },
    {
      artist: "CALPERNIA ADDAMS",
      image: "/images/calpernia-addams.png",
    },
    {
      artist: "candis cayne",
      image: "/images/candis-cayne.png",
    },
    {
      artist: "cheryl hole",
      image: "/images/cheryl-hole.png",
    },
    {
      artist: "daniel franzese",
      image: "/images/daniel-franzese.png",
    },
    {
      artist: "todrick hall",
      image: "/images/todrick-hall.png",
    },
    {
      artist: "pandora boxx",
      image: "/images/pandora-boxx.png",
    },
    {
      artist: "gia gunn",
      image: "/images/gia-gunn.png",
    },
    {
      artist: "ct hedden",
      image: "/images/ct-hedden.png",
    },
    {
      artist: "aurora sexton",
      image: "/images/aurora-sexton.png",
    },
    {
      artist: "brian crum",
      image: "/images/bjc.png",
    },
    {
      artist: "venus d lite",
      image: "/images/venus-d-lite.png",
    },
    {
      artist: "sasha colby",
      image: "/images/sasha-colby.png",
    },
    {
      artist: "ian verdun",
      image: "/images/ian-verdun.png",
    },
    {
      artist: "BOB THE DRAG QUEEN",
      image: "/images/bob.png",
    },
    {
      artist: "JAI RODRIGUEZ",
      image: "/images/jai-rodriguez.png",
    },
    {
      artist: "GEOFFREY MAC",
      image: "/images/geoffrey-mac.png",
    },
    {
      artist: "LEAH ALLYCE CANALI",
      image: "/images/leah-allyce-canali.png",
    },
    {
      artist: "gloria swansong",
      image: "/images/gloria-swansong.png",
    },
    {
      artist: "hibiscus",
      image: "/images/hibiscus.png",
    },
    {
      artist: "lady starlight",
      image: "/images/lady-starlight.png",
    },
    {
      artist: "jaja the kween",
      image: "/images/jaja-the-kween.png",
    },
    {
      artist: "lady german",
      image: "/images/lady-german.png",
    },
    {
      artist: "karina kay",
      image: "/images/karina-kay.png",
    },
    {
      artist: "laganja estranja",
      image: "/images/laganja-estranja.png",
    },
    {
      artist: "lagoona",
      image: "/images/lagoona.png",
    },
    {
      artist: "mocha diva",
      image: "/images/mocha-diva.png",
    },
    {
      artist: "priyanka",
      image: "/images/priyanka.png",
    },
    {
      artist: "santi storm",
      image: "/images/santi-storm.png",
    },
    {
      artist: "SHERRY VINE",
      image: "/images/sherry-vine.png",
    },
    {
      artist: "BAGA CHIPZ*",
      image: "/images/baga-chipz-as-margaret-thatcher.png",
    },
    {
      artist: "ALEXIS MICHELLE*",
      image: "/images/alexis-as-liza.png",
    },
    {
      artist: "ALASKA*",
      image: "/images/alaska-as-mae-west.png",
    },
    {
      artist: "TRINITY THE TUCK*",
      image: "/images/trinity-as-caitlin-jenner.png",
    },
    {
      artist: "NINA WEST*",
      image: "/images/nina-west-as-jo-anne-worley.png",
    },
    {
      artist: "JINKX MONSOON*",
      image: "/images/jinkz-monsoon-as-little-edie.png",
    },
    {
      artist: "SHARON NEEDLES*",
      image: "/images/sharon-needles-as-michelle-visage.png",
    },
    {
      artist: "maxi glamour",
      image: "/images/maxi-glamour.png",
    },
    {
      artist: "amethsyt journey",
      image: "/images/amethsyt-journey.png",
    },
    {
      artist: "KENNEDY DAVENPORT*",
      image: "/images/kennedy-little-richard.png",
    },
    {
      artist: "BOB THE DRAG QUEEN*",
    },
    {
      artist: "ALASKA & JEREMY",
      image: "/images/amethsyt-journey.png",
    },
    {
      artist: "IDAN MATALON",
      image: "/images/idan-matalon.png",
    },
    {
      artist: "KENDOLL",
      image: "/images/kendoll.png",
    },
    {
      artist: "INDIGO",
      image: "/images/indigo.png",
    },
    {
      artist: "MIMI IMFURST",
      image: "/images/mimi-imfurst.png",
    },
    {
      artist: "TINUS",
      image: "/images/tinus.png",
    },
    {
      artist: "JAJA THE QWEEN",
      image: "/images/jaja-the-qween.png",
    },
    {
      artist: "LAGOONA BLOO",
      image: "/images/lagoona-2.png",
    },
    {
      artist: "angel elektra",
      image: "/images/angel-elektra.png",
    },
    {
      artist: "BOB THE DRAG QUEEN*",
      image: "/images/bob-as-carol-channing.png",
    },
    {
      artist: "JILL SOBULE",
      image: "/images/jill-sobule.png",
    },
    {
      artist: "DELTA WORK*",
      image: "/images/delta-work-as-jan-crouch.png",
    },
    {
      artist: "FRANKIE GRANDE",
      image: "/images/frankie-grande.png",
    },
    {
      artist: "JONNY MCGOVERN & LADY RED COUTURE",
      image: "/images/jm-lr.png",
    },
    {
      artist: "BIG DIPPER",
      image: "/images/big-dipper.png",
    },
    {
      artist: "GINGER MINJ*",
      image: "/images/ginger-adele.png",
    },
    {
      artist: "The Katherine",
      image: "/images/the-katherine.png",
    },
    {
      artist: "TRACY YOUNG",
      image: "/images/tracy-young.png",
    },
    {
      artist: "TYNOMI BANKS",
      image: "/images/tynomi-banks.png",
    },
    {
      artist: "ADAM MAC",
      image: "/images/adam-mac.png",
    },
    {
      artist: "Karma B",
      image: "/images/karma-b.png",
    },
    {
      artist: "STEPHEN TRASK",
      image: "/images/stephen-trask.png",
    },
    {
      artist: "KELLY OSBOURNE",
      image: "/images/kelly-osbourne.png",
    },
    {
      artist: "cascada",
      image: "/images/cascada.png",
    },
    {
      artist: "RAJA",
      image: "/images/raja-gemini.png",
    },
    {
      artist: "BRIGHT LIGHT BRIGHT LIGHT",
      image: "/images/BRIGHT-LIGHT-BRIGHT-LIGHT.png",
    },
    {
      artist: "WRABEL",
      image: "/images/WRABEL.png",
    },
    {
      artist: "DARIENNE LAKE",
      image: "/images/DARIENNE-LAKE.png",
    },
    {
      artist: "TRIXIE MATTEL, JUSTIN TRANTER & LELAND",
      image: "/images/TRIXIE-MATTEL-LELAND-JUSTIN-TRANTER.png",
    },
    {
      artist: "Delighted ToBeHere",
      image: "/images/DELIGHTED-TOBEHERE.png",
    },
    {
      artist: "NICHOLE S. PANIC",
      image: "/images/NICHOLE-S-PANIC.png",
    },
    {
      artist: "KAYKAY LAVELLE",
      image: "/images/KAYKAY-LAVELLE.png",
    },
    {
      artist: "BEBE ZAHARA BENET",
      image: "/images/BEBE-ZAHARA-BENET.png",
    },
    {
      artist: "GARRETT CLAYTON",
      image: "/images/GARRETT-CLAYTON.png",
    },
    {
      artist: "AQUARELLA",
      image: "/images/AQUARELLA.png",
    },
    {
      artist: "LESLEY WOLF",
      image: "/images/LESLEY-WOLF.png",
    },
    {
      artist: "MINDY FIRE",
      image: "/images/MINDY-FIRE.png",
    },
    {
      artist: "ALEXIS MICHELLE",
      image: "/images/ALEXIS-MICHELLE.png?cache=bust",
    },
    {
      artist: "Oh My Drag! Presents: Aquarella",
      image: "/images/AQUARELLA.png",
    },
    {
      artist: "Oh My Drag! Presents: Lesley Wolf",
      image: "/images/LESLEY-WOLF.png",
    },
    {
      artist: "Oh My Drag! Presents: Mindy Fire",
      image: "/images/MINDY-FIRE.png",
    },
    {
      artist: "SOPHIE B. HAWKINS",
      image: "/images/SOPHIE-B-HAWKINS.png",
    },
    {
      artist: "PAOLA HOFFMAN",
      image: "/images/PAOLA-HOFFMAN.png",
    },
    {
      artist: "Thorgy Thor",
      image: "/images/THORGY.png",
    },
    {
      artist: "Mimi Marks",
      image: "/images/MIMI-MARKS.png",
    },
    {
      artist: "TINA TWIRLER",
      image: "/images/tina-twirler.png",
    },
    {
      artist: "SIMONA SVENTURA",
      image: "/images/SIMONA-SVENTURA.png",
    },
    {
      artist: "DIGITAL DRAG FEST",
      image: "/images/DDFA.png",
    },
    {
      artist: "TRINITY THE TUCK, BOB THE DRAG QUEEN, MONÉT X CHANGE",
      image: "/images/trinity-bob-monet.png",
    },
    {
      artist: "TRINITY THE TUCK, LATRICE ROYALE, MANILA LUZON",
      image: "/images/trinity-latrice-manila.png",
    },
  ],
  press: [
    {
      headline:
        "Grammy Museum, Digital Drag Fest Announce Celebration of LGBTQ+ Songwriters",
      url:
        "https://www.rollingstone.com/music/music-news/grammy-museum-digital-drag-fest-lgbtq-songwriters-994889/",
      publication: "Rolling Stone",
      date: "2020-05-07T12:00-03:00",
      image: "/images/press/rolling-stone-grammy.jpg",
    },
    {
      headline:
        "Queer festivals, drag performances and LGBTQ meetups turn digital",
      url:
        "https://www.nbcnews.com/feature/nbc-out/queer-festivals-drag-performances-lgbtq-meetups-turn-digital-n1173281",
      publication: "NBC News",
      date: "2020-04-01T12:00-03:00",
      image: "/images/press/nbc.jpg",
    },
    {
      headline:
        "The World’s Most Famous Drag Queens Are Hosting a Digital Drag Festival This Weekend",
      url:
        "https://www.travelandleisure.com/attractions/festivals/rupaul-drag-race-digital-fest",
      publication: "Travel+Leisure",
      date: "2020-03-27T12:00-03:00",
      image: "/images/press/tl.png",
    },
    {
      headline:
        "Your Favorite “Drag Race” Queens Are Getting Dolled Up for “Digital Drag Fest”",
      url: "http://www.newnownext.com/digital-drag-fest/03/2020/",
      publication: "NewNowNext",
      date: "2020-03-24T12:00-03:00",
      image: "/images/press/newnownext.jpg",
    },
    {
      headline: "How Drag Queens Are Going Digital in the Wake of Coronavirus",
      url:
        "https://www.gq.com/story/how-drag-queens-are-going-digital-in-the-wake-of-coronavirus",
      publication: "GQ",
      date: "2020-03-20T12:00-03:00",
      image: "/images/press/gq.jpg",
    },
    {
      headline:
        "Valentina, Blair St. Clair & Over 40 New Artists Added to PEG's 'Digital Drag Fest'",
      url:
        "https://www.billboard.com/articles/news/pride/9340508/peg-digital-drag-fest-expanded-lineup",
      publication: "Billboard",
      date: "2020-03-20T12:00-03:00",
      image: "/images/press/billboard-valentina.jpg",
    },
    {
      headline:
        "Alaska, Monét X Change & More Drag Stars to Perform in 'Digital Drag Fest'",
      url:
        "https://www.billboard.com/articles/news/pride/9339726/alaska-monet-x-change-digital-drag-fest",
      publication: "Billboard",
      date: "2020-03-20T12:00-03:00",
      image: "/images/press/billboard-alaska.jpg",
    },
    {
      headline:
        "RuPaul's Drag Race queens launch digital concert festival amid coronavirus pandemic",
      url: "https://ew.com/tv/rupauls-drag-race-digital-concerts-coronavirus/",
      publication: "EW",
      date: "2020-03-20T12:00-03:00",
      image: "/images/press/ew.jpg",
    },
  ],
  partners: [
    {
      title: "Recording Academy Grammy Museum",
      logo: "/images/partners/ra_gm_logo_black.png",
      // url: "https://www.thebenedettigroup.com/",
    },
  ],
  sponsors: [
    {
      title: "The Benedetti Group",
      logo: "/images/sponsors/benedetti-group.png",
      url: "https://www.thebenedettigroup.com/",
    },
    {
      title: "mybestjudy",
      logo: "/images/sponsors/mybestjudy.png",
    },
    {
      title: "King's Queens Entertainment",
      logo: "/images/sponsors/kings-queens.png",
      url: "https://www.kingsqueensentertainment.com/",
    },
    {
      title: "Amazon",
      logo: "/images/sponsors/amazon.png",
      url: "https://www.amazon.com/",
    },
    {
      title: "GLAAD",
      logo: "/images/sponsors/glaad.png",
      url: "https://www.glaad.org/",
    },
    {
      title: "StageIt",
      logo: "/images/sponsors/stageit.png",
      url: "http://stageit.com/",
    },
    {
      title: "OUTtv",
      logo: "/images/sponsors/outtv.png",
      url: "https://outtv.ca",
    },
  ],
  shop: [
    {
      title: "Digital Drag Fest 2020 T-Shirt (Black)",
      price: 19.99,
      url:
        "https://www.amazon.com/Digital-Drag-Fest-2020-T-Shirt/dp/B0868SZ81Z",
      image: "/images/shop/ddf-tee-black.jpg",
    },
    {
      title: "Digital Drag Fest 2020 T-Shirt (White)",
      price: 19.99,
      url:
        "https://www.amazon.com/Digital-Drag-Fest-2020-T-Shirt/dp/B0868SZ81Z",
      image: "/images/shop/ddf-tee-white.jpg",
    },
    {
      title: "Digital Drag Fest 2020 T-Shirt (Pink)",
      price: 19.99,
      url:
        "https://www.amazon.com/Digital-Drag-Fest-2020-T-Shirt/dp/B0868SZ81Z",
      image: "/images/shop/ddf-tee-pink.jpg",
    },
  ],
  shopNote:
    "50% of all net proceeds from the sale of official festival merch will be donated to support our friends at GLAAD.",
  // shopNote:
  //   "<span>GLAAD</span>, the world’s largest LGBTQ media advocacy organization, has signed on as an official partner. <span>50% of all net proceeds</span> from the sale of official festival merch will be donated to support <span>GLAAD’s advocacy work</span>."
};
