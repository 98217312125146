import React, { useState, useEffect, memo } from "react";
import Ticker from "react-ticker";
import data from "@utilities/data/data.js";
import styles from "./index.module.scss";

const Scroller = () => {
  const maxTickers = data.marquee.length;
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className={styles.marquee}>
      <Ticker direction={"toLeft"} mode={"await"} offset={"run-in"} speed={9}>
        {({ index }) => (
          <>
            <MarqueeContent
              className={styles.ticker}
              index={currentIndex}
              // callbackFunc={() => {
              //   setCurrentIndex(currentIndex + 1);
              // }}
            />
          </>
        )}
      </Ticker>
    </div>
  );
};

const MarqueeContent = ({ index }) => {
  var [random, setRandom] = useState(0);
  const getRandomInt = max => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  useEffect(() => {
    setRandom(getRandomInt(data.marquee.length));
  }, []);
  return <div dangerouslySetInnerHTML={{ __html: data.marquee[random] }} />;
};

export default memo(Scroller);
