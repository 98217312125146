/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import Header from "./header";
import StoreContext from "@context/store.js";
import Cart from "@components/cart";
import Scroller from "@components/scroller";
import "@scss/layout.scss";
import "@scss/page.scss";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <StoreContext.Consumer>
      {store => {
        const { cartOpen } = store;
        return (
          <>
            <Helmet>
              <script src="/js/pace.js" />
              <link href="/css/pace.css" rel="stylesheet" />
              <link
                href="https://fonts.googleapis.com/css?family=Press+Start+2P&display=swap"
                rel="stylesheet"
              />
              <link
                rel="stylesheet"
                href="https://use.typekit.net/kqx2xeu.css"
              />
              <meta
                property="og:image"
                content="https://www.digitialdragfest.com/images/facebook-share.jpg"
              />
            </Helmet>
            <div
              className={`main-container ${
                cartOpen ? "main-container-open" : ""
              }`}
            >
              <main>{children}</main>
              <Scroller />
            </div>
            <div className={`fixed-cart ${cartOpen ? "fixed-cart-open" : ""}`}>
              {cartOpen && (
                <button
                  alt="Click to close cart"
                  title="Click to close cart"
                  onClick={store.toggleCartOpen}
                  className={`fixed-cart-close`}
                />
              )}
              <h3>Cart</h3>
              <div className={`fixed-cart-inner`}>
                <Cart />
              </div>
            </div>
            <div className="footer">
              ©{new Date().getFullYear()}
              <br />
              <a target="_blank" href="https://producerentertainment.com/">
                Producer Entertainment Group
              </a>
            </div>
          </>
        );
      }}
    </StoreContext.Consumer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
